<template>
  <a-config-provider :locale="locale">

    <div class="m3-flex-col m3-align-items-start m3-padding-horizontal-l">

      <SearchForm    @change="searchChange" @search="toSearch"></SearchForm>

      <a-table class="a-table m3-width-full"
               :dataSource="tableList" :columns="tableColumns"
               :scroll="{ x: 1400 }"
               :loading="listLoading" size="small"
               :row-class-name="tableRowClassName"
               :custom-row="customRow"
               :pagination="pagination">
        <!--        <template #company="{record}">-->
        <!--          <div class="m3-line-clamp-1">{{ record.company }}</div>-->
        <!--          &lt;!&ndash;          <div class="m3-line-clamp-1 m3-margin-top-s">{{ record.ip }}</div>&ndash;&gt;-->
        <!--        </template>-->
        <template #tel="{record}">
          <div class="m3-line-clamp-1">主叫：{{ record.telA }}</div>
          <div class="m3-line-clamp-1 m3-margin-top-s">被叫：{{ record.telB }}</div>
        </template>
        <template #riskText="{record}">
          <div :class="riskTextClass(record)"
               class="m3-padding-horizontal-xs m3-padding-vertical-xs m3-text-center md-label-small">
            {{ record.riskText }}
          </div>
        </template>
        <template #industry="{record}">
          <!--          <div class="m3-line-clamp-1">-->
          <!--            报备行业: {{ record.industry }}-->
          <!--          </div>-->
          <div class="m3-line-clamp-1">
            命中类型: {{ record.hitType }}
          </div>
          <div class="m3-line-clamp-1">
            敏感词:{{ record.hitTypeDesc }}
          </div>
        </template>
        <!--      <template #hitTypeDesc="{record}">-->
        <!--        <div class="m3-flex m3-flex-wrap" :style="{maxWidth:'200px'}">-->
        <!--          <a-tag class="m3-margin-right-s m3-margin-bottom-xs" :color="record.auditStatus==2?'error':'default'"-->
        <!--                 v-for="item in record.hitTypeDescArr" :key="item">-->
        <!--            {{ item }}-->
        <!--          </a-tag>-->
        <!--        </div>-->
        <!--      </template>-->
        <template #translatedContent="{record}">
          <p v-if="record.translatedContentHtml"
             :style="{minWidth:'500px'}"
             class="m3-line-clamp-3"
             v-html="record.translatedContentHtml.join('')"/>
        </template>
        <template #auditStatus="{record}">
          <div class="m3-line-clamp-1">
            {{ auditStatusDesc(record) }}
          </div>
        </template>
        <template #translatedResult="{record}">
          <div class="m3-line-clamp-1">
            {{ translatedResultDesc(record) }}
          </div>
          <div class="">
            {{ record.createTime }}
          </div>
        </template>
      </a-table>

      <RecordDialog v-model:dialog-visible="recordDialogVisible" :record="currentRecord"
                    @confirm="recordDialogConfirm"/>
    </div>

  </a-config-provider>
</template>

<script>
import RecordDialog from "@/views/open/apiQuality/record/RecordDialog.vue";
import SearchForm from "@/views/open/apiQuality/record/SearchForm.vue";
import {ATable} from "ant-design-vue/lib/table";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import {computed, reactive, ref, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {useStore} from "vuex";

export default {
  name: "ApiRecord",
  components: {
    RecordDialog,
    SearchForm,
    ATable,
  },
  props: {
    isAdminUser: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    let {isAdminUser} = toRefs(props);
    let store = useStore();

    let locale = zhCN;
    let recordDialogVisible = ref(false);
    let currentRecord = ref({});

    let list = ref([]);
    let listLoading = ref(false);
    let tableList = computed(() => {
      return list.value?.map(item => {
        return item;
      })
    })

    // 自定义column列表
    let customColumnList = ref([]);
    let companyColumn = {
      title: '名称',
      key: 'company',
      dataIndex: 'company',
      width: '150px',
      slots: {customRender: 'company'},
    };
    let tableColumns = ref([
      {
        title: '主叫/被叫',
        key: 'tel',
        width: '150px',
        // dataIndex: 'industry',
        slots: {customRender: 'tel'},
      },
      {
        title: '行业',
        key: 'industry',
        dataIndex: 'industry',
        width: '150px',
        slots: {customRender: 'industry'},
      },
      {
        title: '风险等级',
        dataIndex: 'riskText',
        key: 'riskText',
        width: '80px',
        slots: {customRender: 'riskText'},
      },
      {
        title: '开场白',
        
        // dataIndex: 'translatedContent',
        key: 'translatedContent',
        width: '500px',
        slots: {customRender: 'translatedContent'},
      },
      {
        title: '审核结果',
        dataIndex: 'auditStatus',
        key: 'auditStatus',
        width: '100px',
        slots: {customRender: 'auditStatus'},
      },
      {
        title: '质检状态/日期',
        dataIndex: 'translatedResult',
        key: 'translatedResult',
        width: '180px',
        slots: {customRender: 'translatedResult'},
      },
    ]);

    let getCustomColumnList = async () => {
      let res = await safePromise(_lineService._apiService.getRecordCustomColumnList());
      // optionValue:"{\"cmTime\":\"时间\",\"cmV1\":\"字段1\",\"cmV2\":\"字段2\",\"cmV3\":\"字段3\",\"cmV4\":\"字段4\",\"cmV5\":\"字段5\",\"cmV6\":\"字段6\"}"
      let option = JSON.parse(res?.data?.optionValue)
      // const obj = JSON.parse(str)
      if (!EmptyUtil.isEmpty(option)) {
        for (let prop in option) {
          customColumnList.value.push({
            title: option[prop],
            dataIndex: prop,
            key: prop,
            width: '100px',
          })
        }
        let findIndex = tableColumns.value?.findIndex(item => item.title == '审核结果');

        if (isAdminUser.value || store.state.user.uid == 100064 || store.state.user.uid == 100796) {
          tableColumns.value.splice(findIndex, 0, ...customColumnList.value);
          // console.log('getCustomColumnList', store.state.user.uid, tableColumns.value)
        }
      }


    }
    watch(isAdminUser, (newV, preV) => {
      console.log(' isAdminUser', newV, preV)
      //管理员展示公司名称
      let findIndex = tableColumns.value?.findIndex(item => item.title == companyColumn.title);
      if (newV) {
        if (findIndex === -1) {
          tableColumns.value.splice(0, 0, companyColumn);
        }
      } else {
        if (findIndex !== -1) {
          tableColumns.value.splice(findIndex, 1);
        }
      }
      if (newV || store.state.user.uid == 100064 || store.state.user.uid == 100796) {
        getCustomColumnList();
      }
    }, {immediate: true});

    let customRow = (item, itemIndex) => {
      return {
        // 点击行
        onClick: (event) => {
          // console.log('customRow onClick ', item, itemIndex, event)
          // item.read = 1;
          console.log('点击了',itemIndex);
          currentRecord.value = item;
          recordDialogVisible.value = true;
        },
      }
    }

    let pagination = reactive({
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["10", "20", "30", "40"],
      // 显示总数
      showTotal: (total) => `共 ${total} 条`,
      // 改变每页数量时更新显示
      onShowSizeChange: (current, pageSize) => {
        // console.log('onShowSizeChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getList();
      },
      // 改变页数，跳转页数
      onChange: (current, pageSize) => {
        // console.log('onChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getList();
      },
    });
    let searchForm = {};

    let searchChange = async (search) => {
      if (EmptyUtil.isEmpty(searchForm)) {
        await toSearch(search);
      }
    }
    let toSearch = async (search) => {
      pagination.current = 1;
      searchForm = search;
      await getList();
    }

    let getTranslatedContentHtml = (record) => {
      let keywords = record.hitTypeDesc
      if (EmptyUtil.isEmpty(record.translatedContent)) {
        return ['-']
      }
      let sentences = []
      try {
        sentences = JSON.parse(record.translatedContent)?.payload?.sentences
      } catch (err) {
        // 因查看对话会改变translatedContent值,这里先通过捕获异常处理
        sentences = record.translatedContent
      }
      if (keywords !== '' && keywords != null) {
        // 如果包含关键字,对关键字做高亮处理
        keywords.split(',').forEach((keyword) => {
          sentences.map((sentence) => {
            if (sentence.text.search(keyword) !== -1) {
              sentence.text = sentence.text.replace(
                  keyword,
                  '<b class="keyword-tag" style="color:#ff0000;">' +
                  keyword +
                  '</b>'
              )
              return sentence
            }
          })
        })
      }
      if (sentences == undefined || sentences.length === 0) {
        return ['-']
      }
      return sentences.map((sentence) => sentence.text)
    }


    let getList = async () => {
      // let params = toRaw(searchForm);
      let params = searchForm;
      let paginationParams = {
        page: pagination.current,
        pageSize: pagination.pageSize,
      };
      // console.log('getList', params);
      listLoading.value = true;
      let res = await safePromise(_lineService._apiService.getRecordList({...paginationParams, ...params}))
      res.data?.records?.forEach((item, i) => {
        if (item.hitTypeDesc == null) {
          item.hitTypeDescArr = []
        } else {
          item.hitTypeDescArr = item.hitTypeDesc.split(',')
        }
        item.translatedContentHtml = getTranslatedContentHtml(item);
      });
      pagination.total = res?.data?.total || 0;
      list.value = res?.data?.records || [];
      if (EmptyUtil.isEmpty(list.value) && pagination.current > 1) {
        //如果当前页面列表为空，且不是首页，页数回退一页，这样刷新是可以刷出上一页列表
        pagination.current--;
      }
      listLoading.value = false;
      // console.log('getList', res, res?.data?.records);
    }

    let getNextRecord = async (index) => {
      let res;
      // index:-1:上一个,0：当前不变,1：下一个;
      if (index != 0) {
        //上一个或者下一个
        let findIndex = tableList.value?.findIndex(item => item.id == currentRecord.value.id);
        if (findIndex !== -1) {
          let nextIndex = findIndex + index;
          // console.log('getNextTableData aaa', nextIndex,)
          if (nextIndex >= 0 && nextIndex <= tableList.value.length - 1) {
            res = tableList.value[nextIndex];
          } else if (index < 0 && nextIndex < 0 && pagination.current > 1) {
            //加载上一页
            pagination.current -= 1;
            await getList();
            if (tableList.value && tableList.value.length > 0) {
              res = tableList.value[tableList.value.length - 1];
            }
          } else if (index > 0 && nextIndex > tableList.value.length - 1) {
            //加载下一页
            pagination.current += 1;
            await getList();
            if (tableList.value && tableList.value.length > 0) {
              res = tableList.value[0];
            }
          }
        }
      } else {
        //当前行
        res = tableList.value?.find(item => item.id == currentRecord.value.id);
      }
      return res;
    }

    let recordDialogConfirm = async ({index}) => {
      let nextRecord = await getNextRecord(index);
      // console.log('recordDialogConfirm', nextRecord)
      if (!EmptyUtil.isEmpty(nextRecord) && index != 0) {
        currentRecord.value = nextRecord;
      }
    }
    return {
      locale,
      searchForm, pagination,
      recordDialogVisible, currentRecord, recordDialogConfirm,
      searchChange, toSearch, getList, tableList, tableColumns, listLoading,
      customRow,
    }
  },
  beforeCreate() {
    this.translatedList = [
      {
        value: 0,
        label: '质检成功',
      },
      {
        value: -1,
        label: '质检中',
      },
      {
        value: 1,
        label: '质检失败',
      },
    ];
    this.auditStatusList = [
      {
        value: 0,
        label: '未审核',
      },
      {
        value: 1,
        label: '正常',
      },
      {
        value: 2,
        label: '违规',
      },
      {
        value: 3,
        label: '误判',
      },
      {
        value: 4,
        label: '复审',
      },
    ];
    this.riskList = [
      {
        value: null,
        label: '全部',
      },
      {
        value: 3,
        label: '高',
        labelClass: 'risk-high-more'
      },
      {
        value: 2,
        label: '中',
        labelClass: 'risk-high'
      },
      {
        value: 1,
        label: '低',
        labelClass: 'risk-mid'
      },
      {
        value: 0,
        label: '无',
        labelClass: 'risk-low'
      },
    ];
  },
  computed: {
    tableRowClassName() {
      return (item, itemIndex) => {
        if (item.read === 1 || item.auditStatus === 1) {
          return 'primary-light-row'
        } else if (item.auditStatus === 2) {
          return 'red-row'
        } else if (item.auditStatus === 3) {
          return 'yellow-row'
        } else if (item.auditStatus === 4) {
          return 'yellow-row'
        }
        return '';
      }
    },
    auditStatusDesc() {
      return (item) => {
        let res = '';
        let find = this.auditStatusList.find(auditStatus => auditStatus.value == item.auditStatus);
        res = find?.label || '';
        return res;
      }
    },
    translatedResultDesc() {
      return (item) => {
        let res = '';
        let find = this.translatedList.find(translated => translated.value == item.translatedResult);
        res = find?.label || '';
        return res;
      }
    },
    riskTextClass() {
      return (item) => {
        let res = '';
        let find = this.riskList.find(risk => risk.value == item.risk);
        res = find?.labelClass || '';
        return res;
      }
    },
  },
}
</script>

<style lang="less" scoped>
//注意这里需要deep写法
.a-table :deep(.ant-table-tbody) {
  .red-row {
    background: #fcbebe;
  }

  .yellow-row {
    background: #f3dd94;
  }

  .green-row {
    background: #b2edd9;
  }

  .primary-light-row {
    background: #F0F5FF !important;
  }
}

</style>